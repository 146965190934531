<template>
      <div class="loader text-center mt-4">
            <b-spinner style="width: 5rem; height: 5rem;" variant="primary"></b-spinner>
            <p class="mt-4">{{text}}</p>
      </div>      
</template>


<script>

export default {  
  name: 'Loader',
  props:["loadingtext"],
  computed:
  {
        text()
        {
              return this.loadingtext;
        }
  },
  methods: {    
  }
};
</script>
